import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";

import { useContext } from "react";
import { LoginContext } from "../../../context/loginContext";
import classes from "./style.module.scss";
import pencil from "../../../assets/svg/Pencil.svg";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: "100%",
    minHeight: "40px",
    height: "auto",
    borderRadius: "5px",
    marginTop: "1%",
    marginBottom: "1%",
    backgroundColor:
      state.isFocused && state.isSelected
        ? "#c2c2c2"
        : state.isFocused
        ? "#f0f0f0"
        : state.isSelected
        ? "#d9d9d9"
        : "#fff",
    color: "black",
  }),
  control: (_, { selectProps: { width, height, borderRadius } }) => ({
    border: "1px solid #e8e5e7",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    borderRadius: borderRadius,
    width: width,
    height: height,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontWeight: "500",
    paddingLeft: "10px",
  }),
  indicatorSeparator: (provided, state) => ({
    backgroundColor: "#fff",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    color: "#171e44",
  }),
  menu: (provided, state) => {
    return ({
      ...provided,
      padding: "2%",
      width: state.selectProps.width,
      overflow: "auto",
      minWidth: state.selectProps.minWidth || undefined,
      maxHeight: '300px'
    })
  },
  theme: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#fff" : "#fff",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#868085",
    fontSize: "16px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const CustomSelect = (props) => {
  const { isLoading, selectedBP } = useContext(LoginContext);

  let customOption = ({ children, innerProps }) => {
    let selected = `${classes.optioncontainer}`;
    if (props.value.label === children) {
      selected = `${classes.optioncontainer} ${classes.selected}`;
    }
    return (
      <div {...innerProps} className={selected}>
        <div className={classes.option}>{children}</div>
      </div>
    );
  };
  if (props.type === "edit") {
    customOption = ({ children, innerProps }) => {
      let selected = `${classes.optioncontainer}`;
      if (props.value.label === children) {
        selected = `${classes.optioncontainer} ${classes.selected}`;
      }
      return (
        <div {...innerProps} className={selected}>
          <div className={classes.option}>{children}</div>
          <div className={classes.pencil} onClick={props.onEdit}>
            <img src={pencil} alt="Pencil" />
          </div>
        </div>
      );
    };
  }
  let customValue = ({ children, innerProps }) => {
    return <div className={classes.value}>{children}</div>;
  };

  let customMenuList = ({ children, innerProps }) => {
    return <div {...innerProps}>{children}</div>;
  };
  if (props.type === "button" || props.type === "edit") {
    customMenuList = ({ children, innerProps }) => {
      return (
        <div {...innerProps} className={classes.menucontainer}>
          {children}
          <div className={classes.buttoncontainer}>
            <div className={classes.buttoncontent}>
              <button className={classes.button} onClick={props.onClick}>
                {props.children}
              </button>
            </div>
          </div>
        </div>
      );
    };
  } else if (props.type === "multi-line") {
    customMenuList = ({ children, innerProps }) => {
      let load;
      if (children.length) {
        load = true;
      } else {
        load = false;
      }
      return (
        <div {...innerProps} className={classes.menucontainer}>
          {isLoading && (
            <div className={classes.loadingcontainer}>
              {" "}
              <ClipLoader />{" "}
            </div>
          )}
          {!load && !isLoading && (
            <div className={classes.loadingcontainer}>
              {" "}
              <p>{props.noOptions}</p>{" "}
            </div>
          )}
          {load &&
            children.map((child) => {
              let selected = `${classes.optioncontainer}`;
              if (props.value.value === child.props.data.value) {
                selected = `${classes.optioncontainer} ${classes.selected}`;
              }
              return (
                <div
                  className={selected}
                  key={child.props.data.value}
                  onClick={() => {
                    child.props.selectOption(child.props.data);
                    props.onChange(child.props.data);
                  }}
                >
                  <div className={classes.bpinfo}>
                    <div className={classes.bp}>{child.props.data.label}</div>
                    <div className={classes.bpaddress}>
                      {child.props.data.line1}
                    </div>
                    <div className={classes.email}>
                      <div>{child.props.data.line2}</div>
                      {child.props.data.ehf.find(
                        (sendingMethod) => sendingMethod.method === "peppol"
                      ) && (
                        <div className={classes.ehfcontainer}>
                          <div className={classes.ehf}>EHF</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.pencil} onClick={props.onEdit}>
                    <img src={pencil} alt="Pencil" />
                  </div>
                </div>
              );
            })}
          <div className={classes.buttoncontainer}>
            <div className={classes.buttoncontent}>
              <button className={classes.button} onClick={props.onClick}>
                {props.children}
              </button>
            </div>
          </div>
        </div>
      );
    };
    customOption = () => {
      return <div>{selectedBP.label}</div>;
    };
    customValue = () => {
      return <div className={classes.value}>{selectedBP.label}</div>;
    };
  }

  const noOptions = ({ children, innerProps }) => {
    console.log("t", isLoading);
    return (
      <div className={classes.loadingcontainer}>
        {isLoading && <ClipLoader />}
        {!isLoading && <p>{props.noOptions}</p>}
      </div>
    );
  };

  return (
    <Select
      styles={customStyles}
      options={props.options}
      width={props.width}
      height={props.height}
      menuColor="#f8f7f8"
      onChange={props.onChange}
      onBlur={props.onBlur}
      borderRadius={props.borderRadius}
      value={props.value}
      placeholder={props.placeholder}
      components={{
        MenuList: customMenuList,
        NoOptionsMessage: noOptions,
        Option: customOption,
        SingleValue: customValue,
      }}
      autoFocus={props.autoFocus}
      openMenuOnFocus={true}
      isDisabled={props.disabled}
      minWidth={props.minWidth}
    />
  );
};

export default CustomSelect;
