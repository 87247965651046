/* eslint-disable @typescript-eslint/no-explicit-any */
import { Combobox } from "@headlessui/react";
import { OrgInfoOption } from "./OrgOption";
import classes from "./style.module.css";

export type { Organization } from './Organization';
export { onOrgNameQueryChange } from './onOrgNameQueryChange';


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  LeftIcon?: React.FC;
  isLoading: boolean;
  query: string;
  onQueryChange: (newValue: string) => void;
  optionList: any[] | undefined;
  selectedOption: any;
  onOptionChange: (newOption: any) => void;
}

const Select: React.FC<Props> = ({
  LeftIcon,
  isLoading,
  query,
  onQueryChange,
  optionList,
  selectedOption,
  onOptionChange,
  className,
  ...rest
}) => {
  return (
    <Combobox value={selectedOption?.name} onChange={onOptionChange}>
      <div className={classes.iconcontainer}>
        {!!LeftIcon && (
          <div className={classes.innericoncontainer}>
            <div className={classes.icon}>
              {isLoading ? (
                <svg
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.585 5.707A7.892 7.892 0 005.948 3.06a7.892 7.892 0 107.893 13.67 7.869 7.869 0 003.79-5.274 1.001 1.001 0 011.96.397 9.872 9.872 0 01-4.75 6.61c-4.732 2.731-10.782 1.11-13.514-3.622-2.731-4.73-1.11-10.782 3.62-13.513 4.429-2.556 10.01-1.3 12.948 2.747v-2.18a1 1 0 012 0v5.604a1 1 0 01-1.305.952l-5-1.604a.999.999 0 11.61-1.904l2.385.765z" />
                </svg>
              ) : (
                <svg
                  viewBox="0 0 21 22"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                >
                  <path d="M16.329 17.743A9.958 9.958 0 0110 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10a9.958 9.958 0 01-2.257 6.329l2.964 2.964a.999.999 0 11-1.414 1.414l-2.964-2.964zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" />
                </svg>
              )}
            </div>
          </div>
        )}

        <Combobox.Input
          {...rest}
          onChange={(e) => onQueryChange(e.target.value)}
          className={classes.input}
        />

        {query?.length > 2 && !isLoading && (
          <Combobox.Options className={`${classes.options} ${className}`}>
            <div className={classes.inneroptions}>
              {optionList?.map((option, index) => (
                <Combobox.Option key={index} value={option}>
                  {({ active }) => (
                    <div className={classes.option}>
                      <OrgInfoOption org={option} />
                    </div>
                  )}
                </Combobox.Option>
              ))}
            </div>
            {!optionList?.length && !isLoading && (
              <li className={classes.noresult}>Ingen resultater på søk</li>
            )}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default Select;
