import { Key, MouseEventHandler, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import classes from "./css/commentModal.module.css";
import { Modal, Button, Input } from "../components/common";
import { LoginContext } from "../context/loginContext";
import ErrorModal from "../components/Error";
import QuestionMark from "../components/QuestionMark";


const CommentModal = (props: {
  onClose: MouseEventHandler<HTMLDivElement>;
}) => {
  const [error, setError] = useState(false);
  const {
    extraComment,
    setExtraComment,
    POReference,
    setPOReference,
    setYourReference,
    yourReference,
    fileList,
    setFileList,
  }: any = useContext(LoginContext);

  const { t } = useTranslation();

  const upploadFileHandler = (event: any) => {
    console.log(event);
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (e) => {
      console.log(e.target.result);
      const getbase64 = (dataUrl) => dataUrl.split(",", 2);
      const base64 = getbase64(e.target.result)[1];
      const isPDF = getbase64(e.target.result)[0];
      if (isPDF === "data:application/pdf;base64") {
        setFileList((prevList: any) => [
          ...prevList,
          {
            id: Math.random(),
            type: "ATTACHMENT",
            file_name: event.target.files[0].name,
            content_type: "application/pdf",
            content_base64: base64,
          },
        ]);
      } else {
        setError(true)
      }
    };
  };

  const removeFileHandler = (key: any) => {
    setFileList((prevList: any) =>
      prevList.filter((file: any) => file.id !== key)
    );
  };

  return (
    <Modal onClose={props.onClose} className={classes.modal}>
      <div className={classes.container}>
        <div className={classes.headercontainer}>
          <h1 className={classes.header}>{t("CPA")}</h1>
          <div className={classes.questioncontainer}>
            <QuestionMark
              modalClass={classes.questionmodal}
            >
              <p>Legg inn ekstra fakturadetaljer og vedlegg her.</p>
              <p>
                Merk: Vedlegg blir foreløpig ikke vist i forhåndsvisning, men
                blir lagt til som vedlegg i utsendt epost eller EHF.
              </p>
            </QuestionMark>
          </div>
        </div>
        <div className={classes.input}>
          <Input
            className={classes.input1}
            placeholder={t("POReference")}
            onChange={(e: { target: { value: any } }) =>
              setPOReference(e.target.value)
            }
            value={POReference}
            autoFocus={true}
          ></Input>
          <Input
            className={classes.input2}
            placeholder={t("yourReference")}
            onChange={(e: { target: { value: any } }) =>
              setYourReference(e.target.value)
            }
            value={yourReference}
          ></Input>
          <Input
            className={classes.input3}
            placeholder={t("extraComment")}
            onChange={(e: { target: { value: any } }) =>
              setExtraComment(e.target.value)
            }
            value={extraComment}
          ></Input>
        </div>
        <div className={classes.main}>
          <h2>{t("attachments")}</h2>
          <label className={classes.button} htmlFor="file">
            <input
              type="file"
              id="file"
              className={classes.filebutton}
              onChange={upploadFileHandler}
              value={fileList.file_name}
            ></input>
            {t("upploadAttachments")}
          </label>
        </div>
          
        <div className={classes.listcontainer}>
          {fileList.map((file: { id: Key; file_name: any }) => (
            <div className={classes.listelement} key={file.id}>
              {file.file_name}
              <div
                className={classes.removefile}
                onClick={removeFileHandler.bind(this, file.id)}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.04318 1.0395C1.76656 0.762876 1.31807 0.762876 1.04145 1.0395C0.764829 1.31612 0.764829 1.76461 1.04145 2.04123L5.49897 6.49875L1.04145 10.9563C0.764829 11.2329 0.764829 11.6814 1.04145 11.958C1.31807 12.2346 1.76656 12.2346 2.04318 11.958L6.5007 7.50049L10.9581 11.9579C11.2347 12.2345 11.6832 12.2345 11.9599 11.9579C12.2365 11.6813 12.2365 11.2328 11.9599 10.9562L7.50244 6.49875L11.9599 2.04134C12.2365 1.76472 12.2365 1.31623 11.9599 1.0396C11.6832 0.762983 11.2347 0.762983 10.9581 1.0396L6.5007 5.49702L2.04318 1.0395Z"
                    fill="#171E44"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.buttoncontainer}>
          <Button className={classes.savebutton} onClick={props.onClose}>{t('save')}</Button>
        </div>
      </div>
      {error && <ErrorModal onClose={() => setError(false)}><p>{t('pleaseSelectAPDFDocument')}</p></ErrorModal>}
    </Modal>
  );
};

export default CommentModal;
