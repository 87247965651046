import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import AddCustomerModal from "../modals/addCustomerModal";
import AddCompanyModal from "src/modals/addCompanyModal";
import EditCustomerModal from "../modals/editCustomerModal";
import { LoginContext } from "../context/loginContext";
import classes from "./css/senderAndRecipient.module.css";

import { Select } from "../components/common";

const SenderAndRecipient = (props) => {
  const [addCustomerModalActive, setAddCustomerModalActive] = useState(false);
  const [addCompanyModalActive, setAddCompanyModalActive] = useState(false);
  const [editCustomerModalActive, setEditCustomerModalActive] = useState(false);
  const [EHFTooltipActive, setEHFToolTipActive] = useState(false);
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]);

  const { t } = useTranslation();

  const { dispatch, autoFocus, customerOrgNo } = props;
  const {
    EHF,
    setEHF,
    userData,
    businessPartnersData,
    selectedBP,
    setSelectedBP,
    selectedClient,
    setSelectedClient,
    setSelectedItem,
    setBusinessPartnersData,
    requestItemDataHandler,
    requestBusinessPartnersHandler,
  } = useContext(LoginContext);

  const selectClientHandler = (event) => {
    // localStorage.setItem("selected-client", JSON.stringify(event));
    setSelectedClient(event);
    setSelectedBP({});
    setBusinessPartnersData(null);
    setSelectedItem({});
    dispatch({ type: "RESET" });
    requestItemDataHandler(event);
    requestBusinessPartnersHandler(event);
  };

  let accountOptions = [];

  if (userData !== undefined && userData !== null) {
    accountOptions = userData.map((account) => ({
      value: account.id,
      label: account.display_name,
      address: account.organization.address,
      city: account.organization.city,
      country: account.organization.country,
      vat_liable: account.organization.vat_liable,
    }));
  }

  useEffect(() => {
    if (businessPartnersData) {
      const customers = businessPartnersData.filter(
        (BP) =>
          BP.statistics.is_customer === true ||
          BP.statistics.is_supplier === false
      );

      const bOptions = customers.map((BP) => ({
        ...BP,
        value: BP.id,
        label: BP.name,
        ehf: BP.sending_methods.ARCRN,
        line1: BP.address,
        line2: BP.email,
      }));

      setBusinessPartnerOptions(bOptions);

      const organization = bOptions.find(o => o.organization_number == customerOrgNo);

      if (organization && !selectedBP?.value) {
        setSelectedBP(organization);

        const sendingMethod = organization.sending_methods.ARCRN.find(
          (sendingMethod) => sendingMethod.method === "peppol"
        );
        setEHF(!!sendingMethod);
      }

      if (!organization) {
        axios.get(`/v1/organizations/public/?description=${customerOrgNo}`).then(response => {
          const organizations = response.data?.organizations || [];
          const selectedOrg = organizations.find(o => o.organization_number == customerOrgNo);

          if (selectedOrg) {
            axios.post(`/v1/business-partners?client_account_id=${selectedClient.value}`, {
              name: selectedOrg.name,
              client_account_id: selectedClient.value,
              is_organization: true,
              is_customer: false,
              organization_number: selectedOrg?.organization_number,
              organization_id: selectedOrg?.id,
              email: '',
              address: selectedOrg?.address,
              zip: selectedOrg?.postal,
              city: selectedOrg?.city,
              country: selectedOrg?.country,
            }).then((response) => {
              console.log(response);
              requestBusinessPartnersHandler(selectedClient);
            }).catch((err) => console.log(err));
          }
        });
      }
    }
  }, [businessPartnersData])

  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>{t("from")}</h3>
      <h3 className={classes.heading}>{t("invoiceFor")}</h3>
      <div>
        <Select
          onClick={() => setAddCompanyModalActive(true)}
          onChange={selectClientHandler}
          options={accountOptions}
          width={"95%"}
          height={"50px"}
          borderRadius={"10px"}
          placeholder=""
          value={selectedClient}
          autoFocus={autoFocus}
          noOptions={t("noCompanyOptions")}
          type="button"
        >
          {t("addNewCompany")}
        </Select>
        <div className={classes.emailandpencil}></div>
      </div>
      <div>
        <Select
          onClick={() => setAddCustomerModalActive(true)}
          onChange={setSelectedBP}
          options={businessPartnerOptions}
          width={"95%"}
          height={"50px"}
          borderRadius={"10px"}
          value={selectedBP}
          onEdit={() => setEditCustomerModalActive(true)}
          noOptions={t("noCustomerOptions")}
          type="multi-line"
        >
          {t("addNewCustomer")}
        </Select>
        <div className={classes.emailandpencil}>
          <p className={classes.email}>{selectedBP.email}</p>
          {EHF && (
            <div
              className={classes.ehf}
              onMouseEnter={() => setEHFToolTipActive(true)}
              onMouseLeave={() => setEHFToolTipActive(false)}
            >
              EHF
              {EHFTooltipActive && (
                <div className={classes.tooltip}>
                  <p>
                    Denne boksen indikierer at kunden kan motta EHF og at
                    Snapbooks vil sende faktura på dette formatet.
                  </p>
                  <p>
                    Lurer du på hva EHF er? Les mer her:
                    <a href="https://snapbooks.no/ehf" target="_blank">
                      {" "}
                      https://snapbooks.no/ehf
                    </a>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {addCustomerModalActive && (
        <AddCustomerModal onClose={() => setAddCustomerModalActive(false)} />
      )}
      {addCompanyModalActive && (
        <AddCompanyModal onClose={() => setAddCompanyModalActive(false)} />
      )}
      {editCustomerModalActive && (
        <EditCustomerModal
          isOrganization={selectedBP.is_organization}
          onClose={() => setEditCustomerModalActive(false)}
        />
      )}
    </div>
  );
};

export default SenderAndRecipient;
