import { useState, useContext } from "react";

import { Input, Select } from "../components/common";
import CreateItemModal from "../modals/createitemModal";

import classes from "./css/productLines.module.css";
import { LoginContext } from "../context/loginContext";

import { useTranslation } from "react-i18next";

const ProductLine = (props) => {
  const { dispatch, id, state, index } = props;
  const { itemData, selectedClient, taxCodes } = useContext(LoginContext);

  const [modalActive, setModalActive] = useState(false);
  const [editModalActive, setEditModalActive] = useState(false);
  const [disabledVATModalActive, setDisabledVATModalActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ value: "", label: "" });

  const { t } = useTranslation();

  let newTaxCodes = [...taxCodes];

  if (selectedClient.vat_liable) {
    const codesToShow = [3, 7, 31, 32, 33];
    newTaxCodes = newTaxCodes.filter(t => codesToShow.filter(c => `${c}` == `${t.code}`).length > 0);
  }

  const itemHandler = (e) => {
    let SI;
    if (e.client_account_id) {
      SI = e;
      setSelectedItem({
        label: `${e.item_no} - ${e.description}`,
        value: e.id,
      });
    } else {
      const itemInfo = itemData.find((item) => item.id === e.value);
      SI = itemInfo;
      setSelectedItem(e);
    }

    dispatch({
      type: "ITEM",
      payload: {
        ignore_this_id: id,
        item_no: SI.item_no,
        item_name: SI.description,
        item_id: SI.id,
        unit_id: SI.sales_unit.id,
      },
    });
    dispatch({
      type: "PRICE",
      payload: { ignore_this_id: id, price: SI.price },
    });
    if (selectedClient.vat_liable) {
      dispatch({
        type: "VAT",
        payload: {
          ignore_this_id: id,
          tax_code_id: SI.tax_code.value,
          tax_rate: SI.tax_code.tax_rate,
        },
      });
    } else {
      dispatch({
        type: "VAT",
        payload: {
          ignore_this_id: id,
          tax_code_id: 24,
          tax_rate: "",
        },
      });
    }
    dispatch({
      type: "QUANTITY",
      payload: { ignore_this_id: id, quantity: "1" },
    });
  };

  let itemOptions = [];
  if (itemData && itemData.length > 0) {
    const newItems = itemData.filter(
      (item) => item.tax_code.id !== null || undefined
    );
    itemOptions = newItems.map((item) => ({
      value: item.id,
      label: item.item_no + "  -  " + item.description,
    }));

    if (state[index].item_id && state[index].item_id != selectedItem.value) {
      const itemOption = itemOptions.find(i => i.value == state[index].item_id);
      if (itemOption) {
        setSelectedItem(itemOption);
      }
    }
  }

  const total = state[index].price * state[index].quantity;

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.column1}>
          <div className={classes.row1}>
            <div className={classes.row1container}>
              <div className={classes.product}>
                <Select
                  onClick={() => setModalActive(true)}
                  options={itemOptions}
                  width={"100%"}
                  height={"50px"}
                  borderRadius={"10px"}
                  onChange={itemHandler}
                  placeholder=""
                  value={selectedItem}
                  noOptions={t("noItemOptions")}
                  onEdit={() => setEditModalActive(true)}
                  type="edit"
                >
                  {t("addNewItem")}
                </Select>
              </div>
              <Input
                className={classes.quantity}
                type="number"
                onChange={(e) => {
                  dispatch({
                    type: "QUANTITY",
                    payload: { ignore_this_id: id, quantity: e.target.value },
                  });
                }}
                value={state[index].quantity}
              ></Input>
              <Input
                className={classes.price}
                type="number"
                onChange={(e) => {
                  dispatch({
                    type: "PRICE",
                    payload: { ignore_this_id: id, price: e.target.value },
                  });
                }}
                value={state[index].price}
              ></Input>
              <div
                className={classes.vat}
                onMouseEnter={() => setDisabledVATModalActive(true)}
                onMouseLeave={() => setDisabledVATModalActive(false)}
              >
                <Select
                  options={newTaxCodes}
                  disabled={!selectedClient.vat_liable}
                  width={"100%"}
                  height={"50px"}
                  borderRadius={"10px"}
                  type="plain"
                  minWidth={300}
                  onChange={(e) => {
                    dispatch({
                      type: "VAT",
                      payload: {
                        ignore_this_id: id,
                        tax_code_id: e.value,
                        tax_rate: e.tax_rate,
                        tax_label: e.label
                      },
                    });
                  }}
                  value={{
                    label: state[index].tax_label,
                    value: state[index].tax_code_id,
                    tax_rate: state[index].tax_rate,
                  }}
                />
                {disabledVATModalActive && !selectedClient.vat_liable && (
                  <div className={classes.tooltip}>
                    <p className={classes.text}>
                      Ditt selskap er ikke registrert i MVA registeret og skal
                      da ikke fakturere med MVA.
                    </p>
                    <p className={classes.text}>
                      Fakturerer du for mer enn 50 000,- iløpet av en 12
                      måneders periode skal du registrere deg i MVA registeret.
                    </p>
                    <p className={classes.text}>
                      Når du har gjort dette vil valg av MVA sats i faktura bli
                      tilgjengelig for ditt selskap.
                    </p>
                    <p className={classes.text}>
                      Før bedriften passerer denne grensen skal det ikke legge
                      til MVA på det som selges.
                    </p>
                    <p className={classes.text}>
                      Les mer her:{" "}
                      <a target="_blank" href="https://snapbooks.no/mva/">
                        https://snapbooks.no/mva/
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={classes.row2}>
            <div className={classes.descriptionContainer}>
              <Input
                className={classes.description}
                placeholder={t("description")}
                onChange={(e) => {
                  dispatch({
                    type: "DESCRIPTION",
                    payload: {
                      ignore_this_id: id,
                      description: e.target.value,
                    },
                  });
                }}
                value={state[index].description}
              ></Input>
            </div>
          </div>
        </div>

        <div className={classes.column2}>
          <p className={classes.total}>{total.toLocaleString()}</p>
          <div className={classes.close}>
            <div className={classes.circle} onClick={props.onRemoveInvoice}>
              <svg
                className={classes.svg}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L6.58586 8.00008L0.292893 14.293C-0.0976306 14.6836 -0.0976309 15.3167 0.292893 15.7073C0.683418 16.0978 1.31658 16.0978 1.70711 15.7073L8.00008 9.41429L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.41429 8.00008L15.7071 1.70726C16.0976 1.31673 16.0976 0.683569 15.7071 0.293044C15.3166 -0.09748 14.6834 -0.09748 14.2929 0.293044L8.00008 6.58586L1.70711 0.292893Z"
                  fill="#171E44"
                />
              </svg>
            </div>
          </div>
        </div>

        {modalActive && (
          <CreateItemModal
            onClose={() => setModalActive(false)}
            editing={false}
            selectedItem={selectedItem}
          />
        )}
        {editModalActive && (
          <CreateItemModal
            onUpdateForm={itemHandler}
            onClose={() => setEditModalActive(false)}
            selectedItem={selectedItem}
            editing={true}
          />
        )}
      </div>
    </div>
  );
};

export default ProductLine;
