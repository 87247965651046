import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import classes from "./css/dateAndAttachment.module.css";
import CommentModal from "../modals/commentModal";
import { DatePicker } from "../components/common";
import { LoginContext } from "../context/loginContext";
import pencil from '../assets/svg/Pencil.svg';

import ErrorModal from "../components/Error";

const DateAndAttachment = () => {
  const [modalActive, setModalActive] = useState(false);
  const [oldDateModal, setOldDateModal] = useState(false);

  const { t } = useTranslation();

  const { setDateIssued, setDueDate, dateIssued, dueDate, selectedBP, businessPartnersData } = useContext(LoginContext);

  useEffect(() => {
    if (selectedBP.value) {
      const BPInfo = businessPartnersData.find(
        (account) => account.id === selectedBP.value
      );

      if (BPInfo?.statistics?.receivable_credit_days) {
        const date = new Date()
        setDueDate(date.setDate(date.getDate() + BPInfo.statistics.receivable_credit_days))
      }
    }
  },[selectedBP])

  const date = new Date()
  useEffect(() => {
    if ((date.setDate(date.getDate() - 16) > dueDate) || (date.setDate(date.getDate() - 16) > dateIssued)) {
      setOldDateModal(true)
    } else {
      setOldDateModal(false)
    }
  },[dateIssued, dueDate])

  return (
    <div className={classes.container}>
      <div className={classes.innercontainer}>
        <div className={classes.gridcontainer}>
          <div className={classes.grid1}>
            <p className={classes.issued}>{t("issued")}</p>
            <div className={classes.datepicker}>
              <DatePicker
                onChange={(date) => {
                  const d = new Date(date)
                  setDateIssued(d.setDate(d.getDate()))
                }}
                selected={dateIssued}
              />
            </div>
          </div>
          <div className={classes.grid2}>
            <p className={classes.duedate}>{t("dueDate")}</p>
            <div className={classes.datepicker}>
              <DatePicker
                onChange={(date) => {
                  const d = new Date(date)
                  return setDueDate(d.setDate(d.getDate()));
                }}
                selected={dueDate}
              />
            </div>
          </div>
          <div className={classes.grid3}>
            <p className={classes.comment}>{t("CPA")}</p>
            <div
              className={classes.datepicker}
              onClick={() => {
                setModalActive(true);
              }}
            >
              <img src={pencil} alt="pencil"/>
            </div>
          </div>
        </div>
      </div>
      {modalActive && <CommentModal onClose={() => setModalActive(false)} />}
      {oldDateModal && <ErrorModal onClose={() => setOldDateModal(false)}>{t('oldDate')}</ErrorModal>}
    </div>
  );
};

export default DateAndAttachment;
