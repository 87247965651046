import { Organization } from './Organization';
import classes from "./style.module.css";

export const OrgInfoOption = ({ org }: { org: Organization }) => {
  let place = org.address
  if (org.postal) place += ', ' + org.postal
  if (org.city) place += ', ' + org.city
  return (
    <>
      <p className={classes.bold}>{org.name}</p>
      <p className={classes.text}>{org.organization_number}</p>
      <p className={classes.light}>{place}</p>
    </>
  )
}
