import { useTranslation } from "react-i18next";

import { Modal, Button } from "../components/common";
import classes from "./css/refreshModal.module.css";

const RefreshModal = () => {
  const { t } = useTranslation();

  return (
    <Modal onClose={undefined} className={classes.modal}>
      <div className={classes.container}>
        <p className={classes.text}>{t("invoiceWasSuccessullySent")}</p>
        <form onSubmit={() => window.location.reload()}>
          <Button className={classes.button} type={"submit"}>
            OK
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default RefreshModal;
