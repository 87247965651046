import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import classes from './css/addcustomerModal.module.css';
import { LoginContext } from "../context/loginContext";

import { Modal, Button, Input, Select } from "../components/common";

import ComboInput, { Organization, onOrgNameQueryChange } from "../components/OrgSelect";

const countryOptions = [{
  label: 'Norge',
  value: 'NO',
},{
  label: 'England',
  value: 'EN'
}]


const AddCustomerModal = (props) => {
  const [radioValue, setRadioValue] = useState("company");

  const [orgs, setOrgs] = useState<Organization[]>();
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const [orgNameQuery, setOrgNameQuery] = useState("");
  const [isOrgQueryLoading, setIsOrgQueryLoading] = useState(false);

  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [postalCode, setPostalCode] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState({value: 'NO', label: 'Norge'});
  const [email, setEmail] = useState();

  const { t } = useTranslation();

  const {
    selectedClient,
    requestBusinessPartnersHandler,
  }: any = useContext(LoginContext);

  const onQueryChange = (query) => {
    onOrgNameQueryChange(query, setOrgNameQuery, selectedOrg, setOrgs, setIsOrgQueryLoading)
  };

  let is_organization = false;

  if (radioValue === "person") {
    is_organization = false;
  } else if (radioValue === "company") {
    is_organization = true;
  }

  const addCustomerRequest = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    console.log('org',selectedOrg)
    const params = `?client_account_id=${selectedClient.value}`;
    console.log(params);

    if (radioValue === "person") {
      axios
        .post("/v1/business-partners" + params, {
          name: name,
          client_account_id: selectedClient.value,
          is_organization: is_organization,
          is_customer: false,
          organization_number: "",
          organization_id: null,
          email: email,
          address: address,
          zip: postalCode,
          city: city,
          country: country.value,
        })
        .then((response) => {
          console.log(response);
          requestBusinessPartnersHandler(selectedClient);
        })
        .catch((error) => console.log(error));
    } else if (radioValue === "company") {
      axios
        .post("/v1/business-partners" + params, {
          name: selectedOrg.name,
          client_account_id: selectedClient.value,
          is_organization: true,
          is_customer: false,
          organization_number: selectedOrg?.organization_number,
          organization_id: selectedOrg?.id,
          email: '',
          address: selectedOrg?.address,
          zip: selectedOrg?.postal,
          city: selectedOrg?.city,
          country: selectedOrg?.country,
        })
        .then((response) => {
          console.log(response);
          requestBusinessPartnersHandler(selectedClient);
        })
        .catch((err) => console.log(err));
    }

    props.onClose();
  };

  return (
    <Modal onClose={props.onClose} className={undefined}>
      <div className={classes.container}>
        <div>
          <h1 className={classes.header}>{t("addCustomer")}</h1>
        </div>

        <div className={classes.radioContainer}>
          <label className={classes.customRadioButton}>
            <input
              type="radio"
              id="Company"
              name="customer"
              value="company"
              checked={radioValue === 'company'}
              onChange={(e) => setRadioValue(e.target.value)}
            ></input>
            <span className={classes.checkmark}></span>
            <p className={classes.text}>{t("company")}</p>
          </label>
          <label className={classes.customRadioButton}>
            <input
              type="radio"
              id="Person"
              name="customer"
              value="person"
              onChange={(e) => setRadioValue(e.target.value)}
            ></input>
            <span className={classes.checkmark}></span>
            <p className={classes.text}>Person</p>
          </label>
        </div>
        {radioValue === "person" && (
          <form onSubmit={addCustomerRequest}>
            <div>
              <div className={classes.grid}>
                <p>{t("fullName")}</p>
                <p>{t("address")}</p>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  autoFocus={true}
                ></Input>
                <Input onChange={(e) => setAddress(e.target.value)}></Input>
                <p>{t("email")}</p>
                <p>{t("postalCode")}</p>
                <Input onChange={(e) => setEmail(e.target.value)}></Input>
                <Input onChange={(e) => setPostalCode(e.target.value)}></Input>
                <p>{t("country")}</p>
                <p>{t("city")}</p>
                <Select options={countryOptions} onChange={setCountry} height={'50px'} borderRadius={'12px'} width={'100%'} value={country}/>
                <Input onChange={(e) => setCity(e.target.value)}></Input>
              </div>
            </div>

            <Button className={classes.button} type={"submit"}>
              {t("save")}
            </Button>
          </form>
        )}

        {radioValue === "company" && (
          <div>
            <div className={classes.search}>
              <p className={classes.selskap}>
                {t("company")}
                <span className={classes.light}>
                  {selectedOrg && !isOrgQueryLoading
                    ? " " +
                      `${t("withOrgNr")}` +
                      selectedOrg.organization_number
                    : ""}
                </span>
              </p>
              <ComboInput
                isLoading={isOrgQueryLoading}
                query={orgNameQuery}
                onQueryChange={onQueryChange}
                optionList={orgs}
                selectedOption={selectedOrg}
                onOptionChange={setSelectedOrg}
                placeholder={t("searchForCompany")}
                className={classes.comboinput}
              />
            </div>
            <Button className={classes.button} onClick={addCustomerRequest}>
              {t("save")}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddCustomerModal;