import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Modal, Button, Input, Select } from "../components/common";
import classes from "./css/editCustomerModal.module.css";
import { LoginContext } from "../context/loginContext";

const countryOptions = [
  {
    label: "Norge",
    value: "NO",
  },
  {
    label: "England",
    value: "EN",
  },
];

const EditCustomerModal = (props) => {
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [postalCode, setPostalCode] = useState(undefined);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState({ value: "", label: "" });
  const [email, setEmail] = useState("");

  const { isOrganization, onClose } = props;

  const {
    selectedBP,
    businessPartnersData,
    selectedClient,
    requestBusinessPartnersHandler,
    setSelectedBP,
  }: any = useContext(LoginContext);

  const BPInfo = businessPartnersData.find(
    (account: any) => account.id === selectedBP.value
  );

  useEffect(() => {
    setAddress(BPInfo.address);
    setName(BPInfo.name);
    setPostalCode(BPInfo.zip);
    setCity(BPInfo.city);
    setEmail(BPInfo.email);
    setCountry({ value: BPInfo.country, label: BPInfo.country });
  }, [BPInfo]);

  const { t } = useTranslation();

  const params = `?client_account_id=${selectedClient.value}`;

  const editCustomerRequest = async () => {
    try {
      if (isOrganization) {
        await axios.post(`/v1/business-partners/${BPInfo.id}/${params}`, {
          name: name,
          client_account_id: selectedClient.value,
          is_organization: isOrganization,
          is_customer: false,
          organization_number: BPInfo.organization_number,
          organization_id: BPInfo.organization_id,
          email: email,
          address: address,
          zip: postalCode,
          city: city,
          country: country.value,
        });
      } else if (!isOrganization) {
        await axios.post(`/v1/business-partners/${BPInfo.id}/${params}`, {
          name: name,
          client_account_id: selectedClient.value,
          is_organization: isOrganization,
          is_customer: false,
          organization_number: "",
          organization_id: null,
          email: email,
          address: address,
          zip: postalCode,
          city: city,
          country: country.value,
        });
      }
      requestBusinessPartnersHandler(selectedClient);
      setSelectedBP({ value: BPInfo.id, label: name });
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  console.log(BPInfo);

  return (
    <Modal onClose={onClose} className={undefined}>
      <div className={classes.container}>
        <div>
          <h1 className={classes.header}>{t("editCustomer")}</h1>
        </div>
        {!isOrganization && (
          <div>
            <div>
              <div className={classes.grid}>
                <p>{t("fullName")}</p>
                <p>{t("address")}</p>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  autoFocus={true}
                  value={name}
                ></Input>
                <Input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></Input>
                <p>{t("email")}</p>
                <p>{t("postalCode")}</p>
                <Input
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                ></Input>
                <Input
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                ></Input>
                <p>{t("country")}</p>
                <p>{t("city")}</p>
                <Select
                  options={countryOptions}
                  onChange={(e) => setCountry(e)}
                  height={"50px"}
                  borderRadius={"12px"}
                  width={"97%"}
                  value={country}
                />
                <Input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                ></Input>
              </div>
            </div>

            <Button onClick={editCustomerRequest} className={classes.button}>
              {t("save")}
            </Button>
          </div>
        )}
        {isOrganization && (
          <div>
            <div className={classes.grid}>
              <p>{t("companyName")}</p>
              <p>{t("org")}</p>
              <Input onChange={(e) => setName(e.target.value)} value={name} />
              <Input value={BPInfo.organization_number} disabled={true} />
              <p>{t("email")}</p>
              <p>{t("postalCode")}</p>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                value={email}
              />
              <Input
                onChange={(e) => setPostalCode(e.target.value)}
                value={postalCode}
              />
              <p>{t("country")}</p>
              <p>{t("city")}</p>
              <Select
                options={countryOptions}
                onChange={(e) => setCountry(e)}
                height={"50px"}
                borderRadius={"12px"}
                width={"97%"}
                value={country}
              />
              <Input onChange={(e) => setCity(e.target.value)} value={city} />
            </div>
            <div className={classes.footer}>
              <p>{t("address")}</p>
              <Input
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                className={classes.input}
              />
            </div>
            <Button className={classes.button} onClick={editCustomerRequest}>
              {t("save")}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditCustomerModal;
