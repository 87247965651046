import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Viewer } from "@react-pdf-viewer/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import { LoginContext } from "../context/loginContext";
import classes from "./css/previewAndSend.module.css";
import RefreshModal from "../modals/refreshModal";
import { Modal, Button } from "../components/common";
import ErrorModal from "../components/Error";
import Snapbooks from "../assets/svg/Snapbooks.svg";
import Logo from "../assets/svg/Logo.svg";

const formatter = new Intl.NumberFormat("no-NO", {
  style: "currency",
  currency: "NOK",
  minimumFractionDigits: 2,
});

const overide = {
  position: "fixed",
  height: "10rem",
  width: "10rem",
  top: "calc(50% - 5rem)",
  left: "calc(50% - 5rem)",
};

const validationHandler = (
  state,
  selectedBP,
  noPriceMessage,
  noCustomerMessage,
  noQuantityMessage
) => {
  let response;
  const linesPrice = state.find(
    (line) => line.price === ("0" || 0 || undefined || null)
  );
  const linesQuantity = state.find(
    (line) => line.quantity === ("0" || 0 || undefined || null)
  );
  response = linesPrice
    ? { message: noPriceMessage }
    : !selectedBP.value
    ? { message: noCustomerMessage }
    : linesQuantity
    ? { message: noQuantityMessage }
    : { message: "" };
  return response;
};

let errorResponse = { message: "" };

const PreviewAndSend = (props) => {
  const {
    selectedClient,
    selectedBP,
    dateIssued,
    dueDate,
    yourReference,
    extraComment,
    POReference,
    fileList,
    businessPartnersData,
    EHF,
  } = useContext(LoginContext);

  const [modalActive, setModalActive] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [confirmModalActive, setConfirmModalActive] = useState(false);
  const [PDFUrl, setPDFUrl] = useState("");
  const [BP, setBP] = useState({ email: "" });

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [subtotal, setSubtotal] = useState(0);
  const [VAT25, setVAT25] = useState(0);
  const [VAT15, setVAT15] = useState(0);
  const [VAT12, setVAT12] = useState(0);
  const [VAT11, setVAT11] = useState(0);

  const { t } = useTranslation();

  const { state } = props;

  useEffect(() => {
    if (businessPartnersData && businessPartnersData.length > 0 && selectedBP.value) {
      setBP(businessPartnersData.find((BP) => BP.id === selectedBP.value));
    }
  }, [businessPartnersData, selectedBP]);

  const sendInvoiceRequest = async () => {
    setIsLoading(true);
    let errorResponse;
    setConfirmModalActive(false);
    try {
      errorResponse = validationHandler(
        state,
        selectedBP,
        t("priceCantBeZero"),
        t("pleaseChooseACustomer"),
        t("pleaseSelectAQuantity")
      );
      if (errorResponse.message !== "") {
        throw new Error();
      }
      if (!EHF && BP.email === "") {
        errorResponse.message = t(
          "pleaseCheckThatTheCustomerEmailAddressIsNotMissing"
        );
        throw new Error();
      }

      const newDD = new Date(dueDate);
      const newDI = new Date(dateIssued);
      const newDateIssued = newDI.toISOString().split("T")[0];
      const newDueDate = newDD.toISOString().split("T")[0];
      const response = await axios.post(
        "/v1/documents",
        {
          client_account_id: selectedClient.value,
          business_partner_id: selectedBP.value,
          document_type: "ARINV",
          document_date: newDateIssued,
          due_date: newDueDate,
          currency_code: "NOK",
          lines: state,
          buyer_reference: yourReference,
          order_reference: POReference,
          note: extraComment,
          send_document: true,
          files: fileList,
        },
        {
          transformResponse: (res) => {
            const json = JSON.parse(res);
            errorResponse = { message: json.message };
            return res;
          },
        }
      );
      console.log(response);
      setRefreshModal(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(true);
    }
  };

  const getPDFRequest = async () => {
    setIsLoading(true);
    try {
      errorResponse = validationHandler(
        state,
        selectedBP,
        t("priceCantBeZero"),
        t("pleaseChooseACustomer"),
        t("pleaseSelectAQuantity")
      );
      if (errorResponse.message !== "") {
        throw new Error();
      }
      if (!EHF && BP.email === "") {
        errorResponse.message = t(
          "pleaseCheckThatTheCustomerEmailAddressIsNotMissing"
        );
        throw new Error();
      }

      const newDD = new Date(dueDate);
      const newDI = new Date(dateIssued);
      const newDateIssued = newDI.toISOString().split("T")[0];
      const newDueDate = newDD.toISOString().split("T")[0];
      console.log(fileList);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      const response = await axios.post(
        "/v1/documents/preview",
        {
          client_account_id: selectedClient.value,
          business_partner_id: selectedBP.value,
          document_type: "ARINV",
          document_date: newDateIssued,
          due_date: newDueDate,
          currency_code: "NOK",
          buyer_reference: yourReference,
          order_reference: POReference,
          note: extraComment,
          lines: state,
          send_document: false,
          files: fileList,
        },
        {
          responseType: "arraybuffer",
          transformResponse: (res) => {
            if (res.byteLength < 20000) {
              errorResponse = JSON.parse(
                String.fromCharCode.apply(null, new Uint8Array(res))
              );
            }
            return res;
          },
        }
      );
      setIsLoading(false);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.addEventListener("loadend", (result) => {
        console.log(result.target.result);
        setPDFUrl(result.target.result);
        setModalActive(true);
      });
    } catch (e) {
      setError(true);
      setIsLoading(false);
    }
  };

  const VATDisplay = (vat, rate, text) => {
    return (
      <div className={classes.innercontainer}>
        <p className={classes.text}>
          {t(text)} {rate}
        </p>
        <p className={classes.value}>{formatter.format(vat)}</p>
      </div>
    );
  };

  const VATCalculator = useCallback(
    (vat) => {
      return state.reduce((acc, cur) => {
        if (parseInt(cur.tax_rate) === vat) {
          return Number(acc + (cur.price * cur.quantity * vat) / 100);
        }
        return acc;
      }, 0);
    },
    [state]
  );

  useEffect(() => {
    setSubtotal(
      state.reduce((acc, cur) => {
        return acc + cur.price * cur.quantity;
      }, 0)
    );
    setVAT25(VATCalculator(25));
    setVAT15(VATCalculator(15));
    setVAT12(VATCalculator(12));
    setVAT11(VATCalculator(11));
  }, [state, VATCalculator, setVAT11, setVAT12, setVAT15, setVAT25]);

  const ehf = EHF
    ? "EHF"
    : BP.email !== "" || null || undefined
    ? `${t("email")} ${BP.email}`
    : "";

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.infocontainer}></div>
        {false &&
          <div className={classes.infocontainer}>
            <div className={classes.infoheader}>
              <div className={classes.logocontainer}>
                <img className={classes.logo} src={Logo} alt="logo" />
              </div>
              <img
                className={classes.snapbooks}
                src={Snapbooks}
                alt="snapbooks"
              />
            </div>
            <div className={classes.textcontainer}>
              <p className={classes.infotext}>
                Snapbooks - din digitale regnskapsfører tilbyr fortløpende
                oppdatert regnskap til fastpris.
              </p>
              <p className={classes.infotext}>
                Få kontroll over regnskapet og chat med regnskapsfører via
                appen.
              </p>
              <a
                href="https://snapbooks.no"
                target="_blank"
                className={`${classes.infotext} ${classes.underline}`}
              >
                Trenger du hjelp med regnskap?
              </a>
            </div>
          </div>
        }
        <div className={classes.wrapper}>
          <div className={classes.innerwrapper}>
            <div className={classes.container}>
              <div className={classes.innercontainer}>
                <p className={classes.text}>{t("subtotal")}</p>
                <p className={classes.value}>{formatter.format(subtotal)}</p>
              </div>

              {VAT25 > 0 && VATDisplay(VAT25, "25%", "VAT")}
              {VAT15 > 0 && VATDisplay(VAT15, "15%", "VAT")}
              {VAT12 > 0 && VATDisplay(VAT12, "12%", "VAT")}
              {VAT11 > 0 && VATDisplay(VAT11, "11%", "VAT")}
              {VATDisplay(
                VAT15 + VAT25 + subtotal + VAT11 + VAT12,
                "",
                "amountDue"
              )}
            </div>

            <div className={classes.buttoncontainer}>
              <div className={classes.innerbuttoncontainer}>
                <div className={classes.innerbutton}>
                  <Button className={classes.button} onClick={getPDFRequest}>
                    {t("preview")}
                  </Button>
                </div>
                <div className={classes.innerbutton}>
                  <Button
                    className={classes.button}
                    onClick={() => setConfirmModalActive(true)}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <ClipLoader cssOverride={overide} loading={isLoading} />}
      {confirmModalActive && (
        <Modal
          onClose={() => setConfirmModalActive(false)}
          className={classes.modal}
        >
          <div className={classes.centered}>
            {!EHF && (BP.email === "" || undefined || null) && (
              <div className={classes.sendcontainer}>
                <p className={classes.confirmtext}>
                  {t("theReciverDoesNotAcceptEHFAndYouHaveNotSpecifiedAnEmail")}
                </p>
                <Button onClick={() => setConfirmModalActive(false)}>Ok</Button>
              </div>
            )}
            {(EHF || BP.email !== "") && (
              <div className={classes.sendcontainer}>
                <p className={classes.confirmtext}>{`${t(
                  "invoiceWillBeSentTo"
                )} ${selectedBP.label} ${t("by")} ${ehf}. ${t(
                  "wouldYouLikeToContinue"
                )}`}</p>
                <Button onClick={sendInvoiceRequest}>{t("continue")}</Button>
              </div>
            )}
          </div>
        </Modal>
      )}

      {modalActive && (
        <div>
          <div
            className={classes.backdrop}
            onClick={() => setModalActive(false)}
          ></div>
          <div className={classes.blur}></div>
          <div className={classes.pdf}>
            <Viewer fileUrl={PDFUrl} />
          </div>
        </div>
      )}
      {refreshModal && <RefreshModal />}

      {error && (
        <ErrorModal
          onClick={() => setError(false)}
          onClose={() => setError(false)}
        >
          {t("somethingWentWrong")}
          <br />
          <br />
          <p className={classes.error}>{errorResponse.message}</p>
        </ErrorModal>
      )}
    </Fragment>
  );
};

export default PreviewAndSend;
