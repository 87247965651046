import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";

import "./assets/translations/i18n";

import "./assets/styles/global.css";
import App from "./App";
import LoginContextProvider from "./context/loginContext";

import packageJson from '../package.json'

Sentry.init({
  dsn: "https://8e20bd8221274c71a076a9624184eec9@o459671.ingest.sentry.io/6623964",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: `${packageJson.name}@${packageJson.version}`,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <LoginContextProvider>
      <App />
    </LoginContextProvider>
  </BrowserRouter>
);
