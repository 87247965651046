import { useContext, useReducer, useEffect, useState } from "react";
import axios from "axios";
import { Worker } from "@react-pdf-viewer/core";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import classes from "./style.module.css";
import LoginModal from "./modals/loginModal";
import { LoginContext } from "./context/loginContext";
import DateAndAttachment from "./Views/dateAndAttachment";
import SenderAndRecipient from "./Views/senderAndRecipient";
import ProductLine from "./Views/productLines";
import PreviewAndSend from "./Views/previewAndSend";
import { Button } from "./components/common";
import ErrorModal from "./components/Error";
import QuestionMark from "./components/QuestionMark";

import snapbooks from "./assets/svg/Snapbooks.svg";
import pluss from "./assets/svg/Pluss.svg";
import AddCompanyModal from "./modals/addCompanyModal";

const overide = {
  position: "fixed",
  height: "10rem",
  width: "10rem",
  top: "calc(50% - 5rem)",
  left: "calc(50% - 5rem)",
  zIndex: 100
};

const initialState = [
  {
    ignore_this_id: Math.random(),
    item_id: "",
    item_name: "",
    description: "",
    quantity: "",
    price: "",
    unit_id: "",
    currency_code: "NOK",
    tax_code_id: "",
    tax_rate: "",
    tax_label: "",
  },
];

let index;

const reducer = (state, action) => {
  if (action.payload) {
    index = [...state].findIndex(
      (item) => item.ignore_this_id === action.payload.ignore_this_id
    );
  }
  switch (action.type) {
    case "ADD_LINE":
      return [
        ...state,
        {
          ignore_this_id: Math.random(),
          description: action.payload?.description || '',
          currency_code: "NOK",
          quantity: action.payload?.quantity || '',
          item_id: action.payload?.item_id || '',
          item_name: action.payload?.item_name || '',
          price: action.payload?.price || '',
          item_no: action.payload?.item_no || '',
          tax_code_id: action.payload?.tax_code_id || '',
          unit_id: action.payload?.unit_id || '',
          tax_rate: action.payload?.tax_rate || '',
          tax_label: action.payload?.tax_label || ''
        },
      ];
    case "REMOVE_LINE":
      return [...state].filter(
        (item) => item.ignore_this_id !== action.payload
      );
    case "ITEM":
      state[index] = {
        ...state[index],
        item_no: action.payload.item_no,
        item_name: action.payload.item_name,
        item_id: action.payload.item_id,
        unit_id: action.payload.unit_id,
      };
      return [...state];
    case "QUANTITY":
      state[index] = { ...state[index], quantity: action.payload.quantity };
      return [...state];
    case "PRICE":
      state[index] = { ...state[index], price: action.payload.price };
      return [...state];
    case "VAT":
      state[index] = {
        ...state[index],
        tax_code_id: action.payload.tax_code_id,
        tax_rate: action.payload.tax_rate,
        tax_label: action.payload.tax_label
      };
      return [...state];
    case "DESCRIPTION":
      state[index] = {
        ...state[index],
        description: action.payload.description,
      };
      return [...state];
    case "RESET":
      state = [
        {
          ignore_this_id: Math.random(),
          item_id: "",
          item_name: "",
          description: "",
          quantity: "",
          price: "",
          unit_id: "",
          currency_code: "NOK",
          tax_code_id: "",
          tax_rate: "",
          tax_label: ''
        },
      ];
    case "CLEAR":
      state = [];
      return state;
    default:
      throw new Error();
  }
};

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;

const App = () => {
  const [searchParams] = useSearchParams();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState(null);
  const [paramItems, setParamItems] = useState([]);

  const { t } = useTranslation();

  const {
    noCompany,
    setNoCompany,
    loginModalActive,
    setLoginModalActive,
    loggedIn,
    emailInput,
    token,
    userData,
    setUserId,
    setEmailInput,
    setLoginOrOTP,
    setNonce,
    setToken,
    setLoggedIn,
    setSelectedBP,
    setSelectedClient,
    setSelectedItem,
    setDateIssued,
    setDueDate,
    setUserData,
    setBusinessPartnersData,
    itemData,
    setItemData,
    setExtraComment,
    setPOReference,
    setYourReference,
    setFileList,
    setFirstName,
    setLastName,
    firstName,
    lastName,
    userId,
    requestItemDataHandler,
    requestBusinessPartnersHandler,
    selectedClient,
    taxCodes,
    setTaxCodes,
    units,
    setUnits
  } = useContext(LoginContext);

  useEffect(() => {
    const items = [];
    let p = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      const reg = new RegExp('^(item)[0-9](_)[a-z0-9]+$');
      if (reg.test(param)) {
        const num = param.match(/[0-9]+/g);
        if (num && num.length > 0) {
          const i = (+num[0]) - 1;
          if (!items[i]) {
            items[i] = {};
          }

          const key = param.substring(`item${num[0]}_`.length);
          items[i][key] = value;
        }
      } else {
        console.log(param, value);
        p[param] = value;
      }
    }

    if (Object.keys(p).length > 0) {
      setParams(p);
    } else {
      setParams(null);
    }

    setParamItems(items);
  }, [])

  useEffect(() => {
    if (!token) {
      const refreshRequest = async () => {
        try {
          setIsLoading(true);
          const response = await axios.post("/v1/auth/refresh-token");
          if (
            response.data.user.first_name !== null &&
            response.data.user.last_name !== null
          ) {
            setToken("bearer " + response.data.token);
            setLoggedIn(true);
            setEmailInput(response.data.user.email);
            setUserId(response.data.user.id);
            setFirstName(response.data.user.first_name);
            setLastName(response.data.user.last_name);
          }

          setIsLoading(false);
        } catch (err) {
          console.log(err);
          const status = err.response?.status;
          setIsLoading(false);
          if (status == 401) {
            setLoginModalActive(true);
          }
        }
      };
      refreshRequest();
    }
  }, [token])

  useEffect(() => {
    console.log({ token, params })
    axios.defaults.headers.common["authorization"] = token;

    if (token && !noCompany) {
      const asyncRequest = async () => {
        try {
          setIsLoading(true);
          const taxresult = await axios.get("/v1/tax-codes/", {
            headers: { authorization: token },
          });

          const taxcodes = taxresult.data?.tax_codes || [];
          setTaxCodes(taxcodes.map(t => {
            return {
              ...t,
              value: t.code,
              label: `${t.tax_rate || 0}% - ${t.description_nob}`
            }
          }).sort((a,b) => (a.tax_rate > b.tax_rate) ? 1 : ((b.tax_rate > a.tax_rate) ? -1 : 0)))

          const unitsresult = await axios.get("/v1/units/");
          const units = unitsresult.data.units || [];

          setUnits(units.map(t => {
            return {
              ...t,
              value: t.id
            }
          }))


          const result = await axios.get("/v1/client-accounts/", {
            headers: { authorization: token },
          });
          const client_accounts = result.data?.client_accounts || [];
          let clientData;

          setUserData(client_accounts);
          if (params && params.client_orgno) {
            const client = client_accounts.find(c => c.organization?.organization_number == params.client_orgno);

            if (client) {
              clientData = {
                label: client.display_name,
                value: client.id,
                country: client.organization.country,
                city: client.organization.city,
                address: client.organization.address,
                vat_liable: client.organization.vat_liable,
              };
              // setSelectedClient(clientData);
              // localStorage.setItem("selected-client", JSON.stringify(clientData));
            } else {
              const response = await axios.get(`/v1/organizations/public/?description=${params.client_orgno}`);
              const organizations = response.data?.organizations || [];
              const organization = organizations.find(o => o.organization_number == params.client_orgno);
              if (organization) {
                let id;
                const prevComp = client_accounts.find(
                  (item) => item.organization.id === organization.id
                );
                if (prevComp === undefined) {
                  const response = await axios.post("/v1/client-accounts/", {
                    organization_id: organization.id,
                    first_name: firstName,
                    last_name: lastName,
                  });
                  await axios.post(`/v1/client-accounts/${response.data.id}/users`, {
                    user_id: userId,
                    role_id: 3,
                  });
                  id = response.data.id;
                } else {
                  id = prevComp.id;
                }
                await axios.post("/v1/bank-accounts/", {
                  bank_country: organization.country,
                  bban: params.client_bankaccount,
                  client_account_id: id,
                  currency: "NOK",
                  is_invoice_account: "D",
                  is_payment_account: "D",
                  type: "CURRENT",
                });
                const accounts = await axios.get("/v1/client-accounts/");
                const newAccount = accounts.data.client_accounts.find(
                  (account) => account.organization.id === organization.id
                );
                clientData = {
                  label: organization.name,
                  value: newAccount.id,
                  country: organization.country,
                  city: organization.city,
                  address: organization.address,
                  vat_liable: organization.vat_liable
                };
                // setSelectedClient({ label: organization.name, value: newAccount.id });
                // localStorage.setItem("selected-client", JSON.stringify(clientData));
                setUserData(accounts.data.client_accounts);
                setNoCompany(false);
              }
            }

            console.log({ client })
          }

          setIsLoading(false);
          // const client = JSON.parse(localStorage.getItem("selected-client"));
          if (clientData) {
            setSelectedClient(clientData);
            requestItemDataHandler(clientData, token);
            requestBusinessPartnersHandler(clientData, token);
            setNoCompany(false);
          } else {
            setNoCompany(true);
          }
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };
      asyncRequest();
    }
  }, [token, params, noCompany])

  useEffect(() => {
    if (itemData && !isLoading && paramItems.length > 0 && taxCodes) {
      const processData = async () => {
        setIsLoading(true);
        dispatch({ type: "CLEAR" });

        for (let idx = 0; idx < paramItems.length; idx++) {
          const item = paramItems[idx];

          if (item) {
            const savedItem = itemData.find(i => (i.description || '').toLowerCase() == (item.name || '').toLowerCase());
            let taxcode = taxCodes.find(t => t.code == item.taxcode);
            let unit = units.find(t => t.un_ece_rec_20_code == (item.unitid || 'NAR'));

            if (!unit) {
              unit = units.find(t => t.un_ece_rec_20_code == 'NAR');
            }

            if (!selectedClient.vat_liable) {
              taxcode = taxCodes.find(t => t.code == '6');
            }

            // console.log({ savedItem, taxcode })
            if (savedItem) {
              dispatch({
                type: "ADD_LINE",
                payload: {
                  quantity: item.quantity,
                  description: item.description,
                  item_id: savedItem.id,
                  item_name: savedItem.description,
                  price: savedItem.price,
                  item_no: savedItem.item_no,
                  tax_code_id: taxcode ? taxcode.value : savedItem.tax_code_id,
                  unit_id: savedItem.sales_unit_id,
                  tax_rate: taxcode?.tax_rate,
                  tax_label: `${taxcode?.tax_rate || 0}% - ${taxcode?.description_nob}`
                }
              })
            } else {
              try {
                const response = await axios.post("/v1/items", {
                  client_account_id: selectedClient.value,
                  description: item.name,
                  price: item.price,
                  sales_unit_id: unit?.id,
                  tax_code_id: taxcode?.id,
                });
                requestItemDataHandler(selectedClient);
                console.log(response);
              } catch (error) {
                console.error(error);
              }
            }
          }
        }

        setIsLoading(false);
      }
      processData();
    }
  }, [itemData, paramItems, taxCodes])

  const logoutHandler = async () => {
    try {
      await axios.post("/v1/auth/logout");
      // localStorage.removeItem("selected-client");
      setEmailInput("");
      setLoginOrOTP(0);
      setNonce("");
      setToken("");
      setLoggedIn(false);
      setSelectedBP({});
      setSelectedClient({});
      setSelectedItem({});
      setDateIssued(new Date());
      setDueDate(new Date());
      setUserData(undefined);
      setBusinessPartnersData(null);
      setItemData([]);
      setYourReference("");
      setExtraComment("");
      setPOReference("");
      setFileList([]);
      dispatch({ type: "RESET" });
    } catch (error) {
      setError(true);
    }
  };

  let styles = `${classes.button}`;
  if (loggedIn && noCompany && !userData) {
    styles = `${classes.button} ${classes.button2}`;
  }

  return (
    <div className={classes.mainroot}>
      <ClipLoader cssOverride={overide} loading={isLoading} />
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
        <div className={classes.root}>
          <div className={classes.innerroot}>
            <div className={classes.container}>
              <header className={classes.heading}>
                <div className={classes.empty}></div>
                <div className={classes.logo}>
                  <a href="https://snapbooks.no" target="_blank">
                    <img src={snapbooks} alt="snapbooks" />
                  </a>
                </div>
                <div className={classes.loginandquestion}>
                  <QuestionMark
                    className={classes.question}
                    modalClass={classes.questionmodal}
                  >
                    <p>
                      Snapbooks faktura er et helt gratis komplett
                      fakturaprogram.
                    </p>
                    <p>
                      Send faktura i ditt ønskede format; som epost eller EHF
                    </p>
                    <p>
                      Bekreftelse på utsendt faktura vil bli sendt til din
                      e-post med kopi av faktura og tilhørende vedlegg.
                    </p>
                    <p>
                      Det eneste du trenger for å komme i gang er e-posten din.
                      <p>
                        Vi er selvfølgelig tilgjengelig på chat hvis det er noen
                        spørsmål.
                      </p>
                    </p>
                  </QuestionMark>
                  {!loggedIn && (
                    <div className={classes.buttoncontainer}>
                      <Button
                        className={classes.button}
                        onClick={() => setLoginModalActive(true)}
                      >
                        {t("logIn")}
                      </Button>
                    </div>
                  )}
                  {loggedIn && (
                    <div className={classes.buttoncontainer}>
                      <Button className={styles} onClick={logoutHandler}>
                        {t("logOut")}
                      </Button>
                    </div>
                  )}
                </div>
              </header>
              <div className={classes.wrapper}>
                <p className={classes.invoice}>{t("invoice")}</p>
                <p className={classes.email}>{emailInput}</p>
              </div>
            </div>
            <div className={classes.invoicecontainer}>
              <div className={classes.invoiceInformation}>
                <DateAndAttachment />
                <SenderAndRecipient customerOrgNo={params?.customer_orgno} dispatch={dispatch} />
              </div>
            </div>
            <div className={classes.inputInfo}>
              <div className={classes.item}>
                <p className={classes.text}>{t("item")}</p>
              </div>
              <div className={classes.quantity}>
                <p className={classes.text}>{t("quantity")}</p>
              </div>
              <div className={classes.price}>
                <p className={classes.text}>{t("price")}</p>
              </div>
              <div className={classes.vat}>
                <p className={classes.text}>{t("VAT")}</p>
              </div>
              <div className={classes.total}>
                <p className={classes.text}>{t("total")}</p>
              </div>
            </div>
            <div className={classes.instances}>
              {state.map((item, index) => {
                return (
                  <ProductLine
                    key={item.ignore_this_id}
                    id={item.ignore_this_id}
                    index={index}
                    onRemoveInvoice={() =>
                      dispatch({
                        type: "REMOVE_LINE",
                        payload: item.ignore_this_id,
                      })
                    }
                    taxCodes={taxCodes}
                    dispatch={dispatch}
                    state={state}
                  />
                )
              })}
            </div>
            <div className={classes.wrap}>
              <div
                className={classes.circle}
                onClick={() => dispatch({ type: "ADD_LINE" })}
              >
                <div className={classes.align}>
                  <img src={pluss} alt="pluss" />
                </div>
              </div>
              <div className={classes.linecontainer}>
                <p className={classes.line}></p>
              </div>
            </div>
            <PreviewAndSend state={state} />
            {loginModalActive && (
              <LoginModal onClose={() => setLoginModalActive(false)} />
            )}
          </div>
          {!loggedIn && (
            <div
              onClick={() => setLoginModalActive(true)}
              className={classes.backdrop}
            ></div>
          )}
          {loggedIn && noCompany && !userData && (
            <AddCompanyModal
              onClose={() => setNoCompany(false)}
            />
          )}
          {error && (
            <ErrorModal onClose={() => setError(false)}>
              <p>{t("failedToLogOut")}</p>
            </ErrorModal>
          )}
        </div>
      </Worker>
    </div>
  );
};

export default App;
